import React from 'react';
import styled from 'styled-components';
import * as Mixins from '../Mixins';
import * as t from '../Typography';
import Layout, { Content } from '../components/Layout';
import Architecture from '../images/architecture.jpg';
import Deploy from '../images/deploy.png';
import WebDevelopment from '../images/web_development.jpg';
import DigitalPrecense from '../images/digital_precense.png';
import { HireMe } from '../components/Button.js';
import HireMePopup from '../components/HireMePopup.js';
import { media } from '../MediaQueries';
import Colors from '../Colors';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { darken } from 'polished';

const AboveFold = styled.div`
  ${Mixins.aboveFoldMixin}
  padding: 140px 0 60px 0;
  ${t.H1} {
    color: ${Colors.darkest};
  }
`;

const Block = styled.div`
  &:nth-child(odd) {
    border: solid 1px ${darken(0.1, Colors.light)};
    background-color: ${Colors.light};
  }
`;

const BlockContent = styled(Content)`
  ${Mixins.block}
  padding: 100px 40px;
  ${media.tablet`
    flex-direction: column;
    align-items: baseline;
  `};
  ${media.phone`
    padding: 40px 10px;
  `};
  ${t.P} {
    margin-top: 10px;
  }
  ${t.H2} {
    margin-top: 0;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

const DivWrapper = styled.div`
  padding: 80px 30px;
  ${media.tablet`padding: 50px 0;`}
  &:first-child {
    ${media.tablet`
      margin-bottom: 40px;
  `};
  }
`;

const ItemImage = styled.img`
  max-width: 85%;
  position: relative;
  ${media.tablet`max-width: none;`}
`;

const HomepageWrapper = styled.div`
  ${Mixins.wrapper}
  .who-desc {
    display: block;
    margin: 0 auto 60px auto;
    max-width: 90%;
  }
  ${t.LargeP} {
    margin-bottom: 28px;
  }
  ${t.H1} {
    margin: 0 0 20px 0;
  }
  .avatar {
    max-width: 200px;
    width: 80%;
    margin: 0 auto 50px auto;
    border-radius: 50%;
    display: block;
    ${media.tablet`max-width: 70%;`}
  }
  .link {
    padding: 0;
    color: ${Colors.darkest};
    text-decoration: underlined;
    svg {
      margin-left: 7px;
    }
  }
  .portfolio {
    margin: 100px 0 50px 0;
    font-size: 42px;
  }
`;

const WorkWithMe = styled.div`
  padding: 80px;
  width: 73%;
  border-radius: 6px;
  box-shadow: 0 2px 26px 0 rgba(57, 55, 55, 0.08);
  background-color: #ffffff;
  text-align: center;
  position: relative;
  margin: 100px auto -150px auto;
  ${t.LargeP} {
    max-width: 80%;
    margin: 0 auto 28px auto;
  }
  ${media.tablet`
    width: auto;
    padding: 40px;
    margin: 50px 30px -100px 30px;
  `};
`;

class Homepage extends React.Component {
  state = {
    openHireMePopup: false
  };

  handleRequestDemoClose = () => {
    this.setState({
      openHireMePopup: false
    });
  };

  openContactPopup = () => {
    this.setState({
      openHireMePopup: true
    });
  };

  render() {
    const { openHireMePopup } = this.state;
    const { data } = this.props;
    return (
      <HomepageWrapper>
        <Layout theme="white" bigFooter openContactPopup={this.openContactPopup}>
          <AboveFold>
            <Img fluid={data.avatarHomepage.childImageSharp.fluid} alt="Santiago Hyun Dorado" className="avatar" />
            <t.H1 primary align="center">
              Santiago Hyun Dorado
            </t.H1>
            <t.LargeP align="center" max45>
              Ingeniero de sistemas con conocimientos en diseño, desarrollo, integración y despliegue de aplicaciones
              web.
            </t.LargeP>
            <HireMe large onClick={this.openContactPopup} book>
              Contacto
            </HireMe>
          </AboveFold>
          <Content>
            <t.H2 primary align="center" bold>
              Sobre mí
            </t.H2>
            <t.P align="center" max70 className="who-desc">
              La tecnología ha sido un pilar fundamental para permitir el crecimiento exponencial de la industria en
              general. Si miramos unos cuantos años atrás, podemos recordar lo diferente que era la vida sin la
              intervención de los dispositivos inteligentes y sus aplicaciones. Muchos de los trabajos que realizaban
              las personas, fueron actualizados y automatizados por programas de computador, programados para realizar
              dichas tareas mucho más rápido y a un costo mucho menor. Esto significa, que para las empresas el uso de
              la tecnología representa una oportunidad para aprovechar las soluciones informáticas desarrolladas por
              profesionales para crear, automatizar o apoyar procesos relacionados directamente con el contexto de
              negocio. Cada proceso que una empresa logra automatizar, representa tiempo de trabajo que se puede
              aprovechar para realizar otras actividades que generan valor a la empresa.
              <br />
              <br />
              Como Ingeniero de sistemas egresado de la Universidad del Cauca me he desempeñado en el campo del
              desarrollo web, trabajando con tecnologías modernas, herramientas, modelos y procesos que facilitan el
              desarrollo de aplicaciones web de alta calidad. Además, también he realizado labores de diseño de
              arquitecturas de software e implementación de infraestructura basada en servicios en la nube.
            </t.P>
            <t.H2 primary align="center" bold className="portfolio">
              Servicios para cualquier empresa:
            </t.H2>
          </Content>
          <Block>
            <BlockContent>
              <DivWrapper>
                <ItemImage src={DigitalPrecense} alt="Imagen de servicio: Presencia digital" />
              </DivWrapper>
              <DivWrapper>
                <t.H2 bold>Presencia digital</t.H2>
                <t.P>
                  La presencia digital es una solución para las empresas que desean tener un espacio en la web. Esto
                  sirve para exponer sus productos o servicios en motores de búsqueda como Google Search, Yahoo, Bing y
                  demás. Con el propósito de darse a conocer o llegar a clientes que buscan temas relacionados con sus
                  ofertas.
                </t.P>
                <t.P>
                  La presencia digital puede integrar otro tipo de servicios actuales como la integración de redes
                  sociales y blogs para compartir contenido de valor frecuentemente.
                </t.P>
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block>
            <BlockContent>
              <DivWrapper>
                <t.H2 bold>Desarrollo web</t.H2>
                <t.P>
                  El desarrollo web consiste en la gestión de la información de negocio a través de programas de
                  computo, específicamente programados para realizar actividades precisas y accesibles desde cualquier
                  navegador a través de cualquier dispostivo (PCs, tablets y smartphones.)
                </t.P>
                <t.P>
                  El desarrollo de aplicaciones web es un proceso complejo que requiere de diferentes habilidades,
                  tiempo y mucha colaboración de las personas interesadas en el desarollo de un producto software.
                </t.P>
              </DivWrapper>
              <DivWrapper>
                <ItemImage src={WebDevelopment} alt="Imagen de servicio: Desarrollo web" />
              </DivWrapper>
            </BlockContent>
          </Block>
          <Content>
            <t.H2 primary align="center" bold className="portfolio">
              Servicios para empresas de software:
            </t.H2>
          </Content>
          <Block>
            <BlockContent>
              <DivWrapper>
                <ItemImage src={Architecture} alt="Imagen de servicio: Arquitecturas de software" />
              </DivWrapper>
              <DivWrapper>
                <t.H2 bold>Arquitecturas de software</t.H2>
                <t.P>
                  En las fases tempranas del desarrollo de software es de vital importancia captar las necesidades de
                  calidad de un producto software y relacionarlo directamente con el diseño arquitectónico. Esta acción
                  podría marcar la diferencia en el mantenimiento de un producto a través del tiempo.
                </t.P>
                <t.P>
                  Además, siempre ha sido bien sabido que una imagen vale más que mil palabras, es por ello que el
                  diseño arquitectónico es fundamental para transmitir la información de diseño entre las personas
                  encargadas de elaborar un producto.
                </t.P>
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block>
            <BlockContent>
              <DivWrapper>
                <t.H2 bold>Despliegue de software</t.H2>
                <t.P>
                  El despliegue de software es una de las actividades principales en el desarrollo web. Las aplicaciones
                  constantemente evolucionan, y esta evolución está reflejada en cambios directos en el código fuente de
                  cualquier aplicación. Es por ello que se deben realizar entregas prontas a los usuarios finales sin
                  intervenir en el flujo normal del desarrollo del software.
                </t.P>
                <t.P>
                  En la actualidad existen muchas maneras de automatizar este despliegue de productos software sin la
                  inversión de trabajo adicional para cada entrega.
                </t.P>
              </DivWrapper>
              <DivWrapper>
                <ItemImage src={Deploy} alt="Imagen de servicio: Despliegue de software" />
              </DivWrapper>
            </BlockContent>
          </Block>
          <WorkWithMe>
            <t.H1 green>Ponte en contacto conmigo</t.H1>
            <t.LargeP>¿Te interesa trabajar conmigo? Contáctame para más información! </t.LargeP>
            <HireMe onClick={this.openContactPopup} book>
              Contacto
            </HireMe>
          </WorkWithMe>
        </Layout>
        <HireMePopup open={openHireMePopup} handleClose={this.handleRequestDemoClose} />
      </HomepageWrapper>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    avatarHomepage: file(relativePath: { eq: "avatar.jpg" }) {
      ...fluidImage
    }
  }
`;
